// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/****** Elad Shechter's RESET *******/
/*** box sizing border-box for all elements ***/
*,
*::before,
*::after{box-sizing:border-box;}
body{margin: 0}
a{text-decoration:none; color:inherit; cursor:pointer;}
button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
figure{margin:0;}
input::-moz-focus-inner {border:0; padding:0; margin:0;}
ul, ol, dd{margin:0; padding:0; padding-inline-start: 24px;}
h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
p{margin:0;}
cite {font-style:normal;}
fieldset{border-width:0; padding:0; margin:0;}`, "",{"version":3,"sources":["webpack://./src/assets/css/reset.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC,+CAA+C;AAC/C;;SAES,qBAAqB,CAAC;AAC/B,KAAK,SAAS;AACd,EAAE,oBAAoB,EAAE,aAAa,EAAE,cAAc,CAAC;AACtD,OAAO,4BAA4B,EAAE,aAAa,EAAE,cAAc,EAAE,SAAS,EAAE,cAAc,CAAC;AAC9F,OAAO,QAAQ,CAAC;AAChB,yBAAyB,QAAQ,EAAE,SAAS,EAAE,QAAQ,CAAC;AACvD,WAAW,QAAQ,EAAE,SAAS,EAAE,0BAA0B,CAAC;AAC3D,uBAAuB,QAAQ,EAAE,iBAAiB,EAAE,mBAAmB,CAAC;AACxE,EAAE,QAAQ,CAAC;AACX,MAAM,iBAAiB,CAAC;AACxB,SAAS,cAAc,EAAE,SAAS,EAAE,QAAQ,CAAC","sourcesContent":["/****** Elad Shechter's RESET *******/\n/*** box sizing border-box for all elements ***/\n*,\n*::before,\n*::after{box-sizing:border-box;}\nbody{margin: 0}\na{text-decoration:none; color:inherit; cursor:pointer;}\nbutton{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}\nfigure{margin:0;}\ninput::-moz-focus-inner {border:0; padding:0; margin:0;}\nul, ol, dd{margin:0; padding:0; padding-inline-start: 24px;}\nh1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}\np{margin:0;}\ncite {font-style:normal;}\nfieldset{border-width:0; padding:0; margin:0;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
